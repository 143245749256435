import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"politika-konfidencijnosti"} />
		<Helmet>
			<title>
				 AutoCare Pro
			</title>
			<meta name={"description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:title"} content={" AutoCare Pro"} />
			<meta property={"og:description"} content={"Ми в AutoCare Pro розуміємо, що ваш автомобіль – це не просто транспортний засіб, а важлива частина вашого життя. Наша місія – забезпечити його найкращим обслуговуванням, зосередившись на якості, надійності та індивідуальному підході до кожного клієнта.\n"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ed22abe5d277001897f36d/images/351ea54s-960.jpg?v=2024-09-20T08:16:48.579Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="60px 0" background="--color-light">
			<Text font="--headline2" text-align="center" color="--dark" margin="0 0 20px 0">
				Політика конфіденційності AutoCare Pro
			</Text>
			<Text font="--base" text-align="center" color="--darkL2" margin="0 0 40px 0">
				Ця Політика конфіденційності пояснює, як AutoCare Pro збирає, використовує та захищає ваші особисті дані, коли ви використовуєте наш вебсайт та послуги.
			</Text>
			<Box max-width="800px" margin="0 auto" padding="0 20px">
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					1. Збір інформації
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					AutoCare Pro може збирати інформацію, яку ви надаєте нам безпосередньо, наприклад, коли ви телефонуєте, пишете електронною поштою або взаємодієте з нашим вебсайтом. Ми також можемо автоматично збирати інформацію про ваші дії на нашому сайті, зокрема через файли cookie.
				</Text>
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					2. Використання інформації
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					Ми використовуємо вашу інформацію для надання послуг, відповіді на ваші запити, вдосконалення наших послуг, а також для персоналізації вашого досвіду користування сайтом. Ваші дані можуть використовуватись для зв'язку з вами щодо наших послуг та нових пропозицій.
				</Text>
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					3. Захист даних
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					AutoCare Pro використовує відповідні заходи безпеки для захисту вашої особистої інформації від несанкціонованого доступу, розголошення або знищення. Ми використовуємо сучасні технології для забезпечення безпеки ваших даних.
				</Text>
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					4. Розголошення інформації третім сторонам
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					Ми не продаємо, не передаємо і не обмінюємо вашу особисту інформацію з третіми сторонами без вашої згоди, за винятком випадків, коли це необхідно для виконання наших послуг або коли цього вимагає закон.
				</Text>
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					5. Файли cookie
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					Наш вебсайт використовує файли cookie для покращення вашого досвіду перегляду. Ви можете керувати налаштуваннями cookie через налаштування вашого браузера.
				</Text>
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					6. Ваші права
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					Ви маєте право вимагати доступу до ваших особистих даних, їх виправлення або видалення. Якщо ви хочете скористатися цими правами або маєте будь-які питання щодо нашої політики конфіденційності, будь ласка, зв'яжіться з нами.
				</Text>
				<Text font="--headline3" color="--dark" margin="0 0 20px 0">
					7. Контакти
				</Text>
				<Text font="--base" color="--darkL2" margin="0 0 20px 0">
					Якщо у вас виникли питання або ви хочете дізнатися більше про нашу Політику конфіденційності, звертайтесь за вказаними контактами на сторінці контактів.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<Components.Policy />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});